import header from "./components/header";
import body from "./components/body";
import footer from "./components/footer";
import paragraph from "./components/generic/paragraph";
import picture from "./components/generic/picture";
import page from "./components/page";
import columns from "./components/columns";
import column from "./components/column";
import button from "./components/generic/button";
import input from "./components/form/input";
import textarea from "./components/form/textarea";
import radios from "./components/form/radios";
import radio from "./components/form/radio";
import container from "./components/generic/container";

export default editor => {
 const components = [
     header,
     body,
     footer,
     page,
     columns,
     column,
     paragraph,
     picture,
     button,
     input,
     textarea,
     radios,
     radio,
     container
 ]
 components.forEach(c => c(editor))

}
