
export default editor => {
    editor.DomComponents.addType('footer', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => el.tagName === 'SECTION',

        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'section',
                droppable: false,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'section footer bg-red-200'
                },
                components: [
                    {
                        type: 'paragraph',
                        content: `Footer`
                    }
                ]
            }
        }
    });
}
