// import logo from "./header/logo";
// eslint-disable-next-line no-unused-vars
import container from "./generic/container"

export default editor => {
    editor.DomComponents.addType('header', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => el.tagName === 'SECTION',

        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'section',
                droppable: false,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'section et-header bg-blue-200'
                },
                components: [
                    {
                        type: 'paragraph',
                        content: `Header`
                    }
                ]
            }
        }
    });
}
