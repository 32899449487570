//import components from "./components";

const blocks = [
    {
        id: 'page', // id is mandatory
        category: 'Struktur',
        label: 'Formularseite', // You can use HTML/SVG inside labels
        content: {type: 'page'},
        activate: false,
    },{
        id: 'row', // id is mandatory
        category: 'Struktur',
        label: 'Zeile', // You can use HTML/SVG inside labels
        content: {type: 'columns'},
        activate: false,
    },{
        id: 'column', // id is mandatory
        category: 'Struktur',
        label: 'Spalte', // You can use HTML/SVG inside labels
        content: {type: 'column'},
        activate: false,
    }, {
        id: 'text',
        category: 'Elemente',
        label: 'Text',
        content: {type: 'paragraph'},
        activate: true,
    }, {
        id: 'image',
        category: 'Elemente',
        label: 'Bild',
        content: {type: 'image', draggable: '[data-gjs-type=column]',},
        activate: true, // This triggers `active` event on dropped components and the `image`
                       // reacts by opening the AssetManager
        select: true, // Select the component once it's dropped
    }, {
        id: 'button',
        category: 'Elemente',
        label: 'Button',
        content: {type: 'button'},
        // content: components.button({draggable:'[data-gjs-type=column]'}),
        activate: true,
    }, {
        id: 'input',
        category: 'Formular',
        label: 'Eingabefeld',
        content: {type: 'input'},
        // content: components.button({draggable:'[data-gjs-type=column]'}),
        activate: true,
    }, {
        id: 'textarea',
        category: 'Formular',
        label: 'Textfeld',
        content: {type: 'textarea'},
        // content: components.button({draggable:'[data-gjs-type=column]'}),
        activate: true,
    }, {
        id: 'radios',
        category: 'Formular',
        label: 'Radio-Button-Gruppe',
        content: {type: 'radios'},
        // content: components.button({draggable:'[data-gjs-type=column]'}),
        activate: true,
    }
]
export default blocks
